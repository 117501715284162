import { createSlice } from '@reduxjs/toolkit';
import { ExpenseTypesState } from 'src/@types/expenses/expenses';
// utils
import axios from 'src/utils/axios';
// @types
import { dispatch } from 'src/redux/store';

const initialState: ExpenseTypesState = {
    isLoading: false,
    error: null,
    data: { expense_types: [], total_count: 0 }
};

const slice = createSlice({
    name: 'expense_types',
    initialState,
    reducers: {
        // START LOADING
        startLoading: (state) => {
            state.isLoading = true;
            return state;
        },

        // END LOADING
        endLoading: (state) => {
            state.isLoading = false;
            return state;
        },

        // HAS ERROR
        hasError: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
            return state;
        },

        // GET EVENTS
        getTypes: (state, action) => {
            state.isLoading = false;
            state.data = action.payload;
            return state;
        },

        // CREATE 
        createType: (state, action) => {
            state.isLoading = false;
            state.data.total_count = state.data.total_count + 1;
            state.data.expense_types = state.data.expense_types ? [...state.data.expense_types, action.payload] : [action.payload];
            return state;
        },

        // UPDATE 
        updateType: (state, action) => {
            state.isLoading = false;
            if (state.data.expense_types)
                state.data.expense_types = state.data.expense_types.map(type => type.id === action.payload.id ? action.payload : type);
            return state;
        },

        // RESET STATE
        resetState: () => initialState
    },
});

// Reducer
export default slice.reducer;

export function getExpenseTypes(params: any = {}) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(`/expenses/types`, { params: params });
            dispatch(slice.actions.getTypes(response.data));
            return response.data;
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function deleteExpenseType(id: string) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            await axios.delete(`/expenses/types/${id}`);
            dispatch(getExpenseTypes({ offset: 0, limit: 250 }))
            return true
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function createExpenseType(data: any = {}) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.post(`/expenses/types`, data);
            dispatch(slice.actions.createType(response.data));
            return response.data
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function updateExpenseType(id: string, data: any = {}) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.put(`/expenses/types/${id}`, data);
            dispatch(slice.actions.updateType(response.data));
            return response.data
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function reset() {
    return async () => {
        dispatch(slice.actions.resetState())
    }
}