import { ReactNode, useEffect } from 'react';
// notifications
import { useSnackbar } from 'notistack';
// redux
import { reset } from 'src/redux/slices/error/error';
import { dispatch, useSelector } from '../redux/store';
import useLocales from 'src/hooks/useLocales';

type ErrorGuardProps = {
    children: ReactNode;
};

export default function ErrorGuard({ children }: ErrorGuardProps) {
    const { translate } = useLocales();
    const { enqueueSnackbar } = useSnackbar();
    const error = useSelector(state => state.error);

    useEffect(() => {
        if (error.statusCode) {
            enqueueSnackbar(translate(error.statusCode === 403 ? 'common.forbidden' : 'common.something_wrong'), { variant: 'error', autoHideDuration: 3000 });
            dispatch(reset());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error])

    return <>{children}</>;
}
