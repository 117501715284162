import { ReactNode } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
// routes
import { PATH_DASHBOARD, PATH_ONBOARDING } from '../routes/paths';
// components
import { useSelector } from '../redux/store';

type OnboardingGuardProps = {
    children: ReactNode;
};

export default function OnboardingGuard({ children }: OnboardingGuardProps) {
    const location = useLocation();

    const queryParams = new URLSearchParams(location.search);

    const queryParamObject = Object.fromEntries(queryParams.entries());

    let { force_login } = queryParamObject;

    const { data: company, isLoading } = useSelector(state => state.company);

    const { pathname, search } = location;

    const onboarding_paths = [PATH_ONBOARDING.root, PATH_ONBOARDING.onboarding.main]

    if (!isLoading && company && !company?.status && !onboarding_paths.includes(pathname) && force_login !== 'true') {
        console.log(pathname, location)
        localStorage.setItem('onboard_redirect', pathname + search)
        return <Navigate to={PATH_ONBOARDING.onboarding.main} />;
    }

    if (!isLoading && company && !!company.status && onboarding_paths.includes(pathname)) {
        const onboard_redirect = localStorage.getItem('onboard_redirect');

        if (onboard_redirect) {
            localStorage.removeItem('onboard_redirect');
            return <Navigate to={onboard_redirect} />
        } else return <Navigate to={PATH_DASHBOARD.root} />;
    }

    return <>{children}</>;
}
